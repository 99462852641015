import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/adu-design-ideas-maximize-space");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_ADU Design Ideas_ Maximize Space and Functionality.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                When it comes to livable space for your family, every inch counts. That may be why
                accessory dwelling units (ADUs) have gotten a lot of attention lately, including
                Governor Newsom signing three new ADU bills in 2024. These legislative updates offer
                even more flexibility for families and rental income opportunities. So how can you
                make the most of an ADU?
              </CommonParagraph>
              <CommonParagraph>
                You’ve come to the right place! We’re bringing you a few ADU design ideas to help
                you maximize both space and functionality.
              </CommonParagraph>
              <Heading type="h2">Planning Fundamentals</Heading>
              <CommonParagraph>
                Typically, ADUs are compact. That makes thoughtful space planning essential. Before
                kicking off your accessory dwelling unit construction, consider how you’ll use the
                space. Will it be a guest house? Home office?{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/san-diego-real-estate-investment"
                  text="Rental property"
                />
                ? Knowing the purpose of your ADU can help you better tailor the layout to suit your
                needs.
              </CommonParagraph>
              <CommonParagraph>
                No matter its intended purpose, there are three things you’ll want to consider when
                it comes to smart planning and ADU interior design ideas:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Natural light:</strong> Strategically placed skylights, windows, and glass
                  doors can brighten a small space, making it appear larger and more inviting.
                </li>
                <li>
                  <strong>Flexibility:</strong> Foldable partitions and sliding doors can help
                  create private areas without permanently dividing the space.
                </li>
                <li>
                  <strong>Open floor plans:</strong> Focusing on creating an open floor plan can
                  improve flow, giving the illusion of{" "}
                  <BlogLink
                    url="https://selbysellssd.com/blog/maximize-space-san-diego-homes/"
                    text="more space"
                  />
                  .
                </li>
              </ul>
              <Heading type="h2">Furniture</Heading>
              <CommonParagraph>
                Once you know how you’ll use your ADU and have some space planning ideas in mind,
                consider how you’ll fill the space. Because there will be limited living areas,
                consider utilizing multifunctional furniture solutions. When pieces can be used in
                more than one way, you add both versatility and practicality, potentially saving
                money, too, as you won’t have to buy multiple pieces of furniture.
              </CommonParagraph>
              <CommonParagraph>Here are some multifunction solutions to consider:</CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Murphy beds</li>
                <li>Sectional couches with built-in storage</li>
                <li>Expandable tables</li>
                <li>Benches with underneath storage</li>
              </ul>
              <Heading type="h2">Storage</Heading>
              <CommonParagraph>
                Storage can feel challenging in an ADU, but with creative planning, you can give a
                home to everything in your space. To stay organized without sacrificing style, add a
                few:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Hidden storage options, such as ottomans</li>
                <li>Built-in solutions, like under-bed drawers</li>
                <li>Hooks to take advantage of vertical space</li>
              </ul>
              <Heading type="h2">Visual Space</Heading>
              <CommonParagraph>
                Modern ADU designs don’t only focus on what’s functional—they also consider how a
                space feels. When carefully considered, design features can make even the smallest
                ADU feel open, comfortable, and spacious.
              </CommonParagraph>
              <CommonParagraph>
                Here are a few ADU interior design tips that can help you create visual space.
              </CommonParagraph>
              <Heading type="h3">Use Light Colors</Heading>
              <CommonParagraph>
                Neutral tones are often associated with an airy, open feel. Consider whites, beiges,
                and soft grays when choosing your color palette, with pops of bright color for
                personality.
              </CommonParagraph>
              <Heading type="h3">Incorporate Mirrors</Heading>
              <CommonParagraph>
                Because mirrors reflect light, they can give the illusion of more space. Consider
                strategically placing mirrors throughout your accessory dwelling unit to amplify
                natural light.
              </CommonParagraph>
              <Heading type="h3">Select Compact Fixtures</Heading>
              <CommonParagraph>
                From lighting solutions like lamps to appliances like stoves, fixtures can swallow
                up the space if you’re not careful. Opt for sleek, compact options whenever possible
                to help avoid overcrowding your ADU.
              </CommonParagraph>
              <Heading type="h3">Use Vertical Elements</Heading>
              <CommonParagraph>
                A trick of the design trade? Vertical elements. Pieces like bookshelves or tall
                cabinets draw the eye upward, which can help make rooms feel taller and more
                spacious.
              </CommonParagraph>
              <Heading type="h2">Reach Your Real Estate Goals With The Selby Team</Heading>
              <CommonParagraph>
                No matter your real estate aspirations, like maximizing your home’s value through an
                ADU, The Selby Team is here to help. As knowledgeable, experienced San Diego
                realtors, we know how to make your property work smarter for you. Connect with us to
                discuss your{" "}
                <BlogLink url="https://selbysellssd.com/sell-your-san-diego-home/" text="selling" />
                , <BlogLink url="https://selbysellssd.com/buy-house-san-diego/" text="buying" />, or
                building goals—we’ll help you reach them!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
